<template>
  <v-app>
    <div class="contain">
      <v-btn
        elevation="6"
        to="/exam/information"
        large
        x-large
        x-small
        class="button-exam"
      >
        Thêm đề thi
      </v-btn>
    </div>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tạo đề thi",titledad:'Đề thi' }]);
  },
};
</script>
<style scoped>
.button-exam {
  background-color: black;
  position: absolute;
  width: 293px;
  height: 108px;
  left: 567px;
  top: 531px;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 16px;
}
.contain {
  position: relative;
  width: 100%;
  height: 100%;
}
.button-exam {
  background-color: black;
  position: absolute;
  width: 293px;
  height: 108px;
  top: 50%;
  left: 50%;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  transform: translate(-50%, -50%);
}
</style>
